import { CountryCodeEnum } from '_api-client';
import { Flex, HStack, IconButton, Input, Stack, Text } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY } from 'apis/customers';
import { getAttachmentById, updateExemption, uploadResellerCertificate } from 'apis/exemption-apis';
import { EXEMPTIONS_STATE_KEY } from 'apis/exemptions';
import AlertBanner from 'components/alert/alert';
import { RemoveIcon } from 'components/icons';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { Switch } from 'components/ui/switch';
import { toaster } from 'components/ui/toaster';
import { CAStatesByCode, USStatesByCode } from 'constants/app-constants';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { getCountryNameByCode } from 'utils';
import { getHumanReadableString } from 'utils/enum-helpers';
import { date, object, ref, string } from 'yup';

const validationSchema = object().shape({
  start_date: date().required('Start date is required'),
  end_date: date().min(ref('start_date'), "End date can't be before start date").required('End date is required'),
  jurisdiction: string().required('Jurisdiction is required'),
  FEIN: string().required('FEIN/Tax ID is required'),
  sales_tax_id: string().required('Sales Tax Id is required'),
});

type EditExemptionsFormProps = {
  isOpen: boolean;
  onClose: () => void;
  exemption: any;
};

export const EditExemptionsForm = ({ isOpen, onClose, exemption }: EditExemptionsFormProps) => {
  const id = String(exemption.id);
  const { orgId } = useOrg();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();
  const [fileName, setFileName] = useState('');
  const [dateChanged, setDateChanged] = useState(false);
  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [CUSTOMERS_STATE_KEY, orgId] });
    queryClient.invalidateQueries({ queryKey: [EXEMPTIONS_STATE_KEY, orgId] });
  };

  const mutation = useMutation({
    mutationFn: (payload: any) => {
      return updateExemption(orgId, id, payload);
    },
    onSuccess: () => {
      const file = formik.values.certificate_location;
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        uploadResellerCertificate(exemption.id, orgId, formData)
          .then(() => {
            invalidateQuery();
            handleSuccessNotification('Exemption updated successfully');
          })
          .catch(error => {
            handleFailNotification(error);
          });
      } else {
        invalidateQuery();
        handleSuccessNotification('Exemption updated successfully');
      }
      onClose();
    },
  });

  useQuery({
    queryKey: ['getAttachmentById', orgId, exemption?.attachment?.id],
    queryFn: async () => {
      if (exemption?.attachment?.length > 0) {
        const attachmentLength = exemption.attachment.length;
        const res = await getAttachmentById(orgId, exemption.attachment[attachmentLength - 1].id);
        setFileName(res.data.file_name);
        return res.data;
      }
    },
    enabled: exemption?.attachment?.length != 0,
  });

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    handleFileSelection(droppedFile);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileSelection = (selectedFile: File) => {
    if (selectedFile?.type !== 'application/pdf') {
      toaster.create({
        type: 'error',
        title: 'Invalid file type',
        description: 'Only PDF file is allowed.',
      });
      return;
    }
    setFileName(selectedFile?.name);
    formik.setFieldValue('certificate_location', selectedFile);
  };

  const formik = useFormik({
    initialValues: {
      country_code: exemption.country_code,
      exemption_type: exemption.exemption_type,
      customer_id: exemption.customer_id,
      start_date: getHumanReadableString(exemption.start_date),
      end_date: getHumanReadableString(exemption.end_date),
      FEIN: exemption.FEIN,
      sales_tax_id: exemption.sales_tax_id,
      jurisdiction: exemption.jurisdiction,
      certificate_location: '',
      reseller: exemption.reseller,
      status: 'ACTIVE',
      transaction_id: exemption.transaction_id,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      mutation.mutate(values);
    },
  });

  const getStateLabel = (jurisdiction: string) => {
    if (exemption.country_code === CountryCodeEnum.US) {
      const state = USStatesByCode[jurisdiction];
      return state ? state.label : '';
    } else if (exemption.country_code === CountryCodeEnum.CA) {
      const state = CAStatesByCode[jurisdiction];
      return state ? state.label : '';
    } else {
      return jurisdiction;
    }
  };

  return (
    <DialogRoot closeOnInteractOutside={false} placement="center" size={'md'} open={isOpen}>
      <DialogBackdrop />
      <DialogContent colorPalette="blue">
        <DialogCloseTrigger onClick={onClose} />
        <DialogHeader>
          <DialogTitle>Edit Exemption</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <form noValidate>
            <Stack gap={'16px'}>
              <HStack>
                <Field label="Country" required>
                  <Input
                    id="country"
                    name="country"
                    value={getCountryNameByCode(formik.values.country_code)}
                    disabled
                  ></Input>
                </Field>
                <Field label="Jurisdiction" required>
                  <Input id="jurisdiction" name="jurisdiction" value={getStateLabel(exemption.jurisdiction)} disabled />
                </Field>
              </HStack>
              <HStack align={'baseline'}>
                <Field
                  label="Start Date"
                  invalid={!!(formik.errors.start_date && formik.touched.start_date)}
                  errorText={formik.errors.start_date}
                  required
                >
                  <DatePicker
                    selected={formik.values.start_date}
                    onChange={date => {
                      formik.setFieldValue('start_date', date);
                      setDateChanged(true);
                    }}
                  />
                </Field>
                <Field
                  label="End Date"
                  invalid={!!(formik.errors.end_date && formik.touched.end_date)}
                  errorText={formik.errors.end_date}
                  required
                >
                  <DatePicker
                    selected={formik.values.end_date}
                    onChange={date => {
                      formik.setFieldValue('end_date', date);
                      setDateChanged(true);
                    }}
                  />
                </Field>
              </HStack>
              {dateChanged && (
                <AlertBanner
                  message="We will not retroactively update transactions for this exemption"
                  padding={0}
                ></AlertBanner>
              )}
              <HStack align={'baseline'}>
                <Field
                  label="FEIN/Tax ID"
                  invalid={!!(formik.errors.FEIN && formik.touched.FEIN)}
                  errorText={String(formik.errors.FEIN)}
                  required
                >
                  <Input
                    id="FEIN"
                    type="string"
                    name="FEIN"
                    value={formik.values.FEIN}
                    onChange={formik.handleChange}
                  />
                </Field>
                <Field
                  label="Sales Tax ID"
                  invalid={!!(formik.errors.sales_tax_id && formik.touched.sales_tax_id)}
                  errorText={String(formik.errors.sales_tax_id)}
                  required
                >
                  <Input
                    id="sales_tax_id"
                    type="string"
                    name="sales_tax_id"
                    value={formik.values.sales_tax_id}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.sales_tax_id && formik.touched.sales_tax_id && (
                    <Text color={'#E53E3E'}>{String(formik.errors.sales_tax_id)}</Text>
                  )}
                </Field>
              </HStack>

              <Switch
                required
                id="reseller"
                name="reseller"
                checked={formik.values.reseller}
                onCheckedChange={e => {
                  formik.setFieldValue('reseller', e.checked);
                  formik.setFieldValue('exemption_type', e.checked ? 'wholesale' : exemption.exemption_type);
                }}
              >
                Is this customer a reseller?
              </Switch>

              <Text fontWeight={500}>Upload Certificate</Text>
              {formik.values.certificate_location || fileName ? (
                <HStack>
                  <Text>{fileName}</Text>
                  <IconButton
                    variant={'transparent-with-icon'}
                    aria-label="Remove file"
                    onClick={() => {
                      formik.setFieldValue('certificate_location', null);
                      setFileName('');
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </HStack>
              ) : (
                <Flex
                  onDrop={formik.values.reseller ? handleFileDrop : undefined}
                  onDragOver={formik.values.reseller ? handleDragOver : undefined}
                  height="78px"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  border="1px dashed #4285F4"
                  borderRadius="2px"
                  p={'16px'}
                >
                  <Text>
                    Drag and Drop your files here or{' '}
                    <Text as="label" color={'#4285F4'} textDecoration={'underline'} cursor="pointer">
                      Choose File
                      <Input
                        type="file"
                        id="file"
                        accept=".pdf"
                        display="none"
                        onChange={e => {
                          if (e.target.files && e.target.files.length > 0) {
                            handleFileSelection(e.target.files[0]);
                          } else {
                            toaster.create({
                              type: 'error',
                              title: 'Error',
                              description: 'Error selecting file ',
                            });
                          }
                        }}
                        onClick={(e: any) => {
                          e.target.value = '';
                        }}
                      />
                    </Text>
                  </Text>
                  <Text fontSize={'12px'} color={'#5F647B'}>
                    Max file size: 10MB. Supported type: PDF
                  </Text>
                </Flex>
              )}
            </Stack>
          </form>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={() => formik.handleSubmit()}
              loading={mutation.isPending}
            >
              Save
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
